import React, { useCallback, useMemo, useState } from 'react';
import styles from './tenmaxReconciliation.module.scss';
import i18n from 'i18n';
import moment from 'moment';
import { DateRangePickerField } from 'components/form/field/DateRangePickerField';
import { CustomField } from 'components/form/field/CustomField';
import { Button } from 'react-bootstrap';
import { DefaultReportManager } from 'core/report/ReportManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ReconciliationReportType } from 'ws/ReportWebService';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { SelectField } from 'components/form/field/SelectField';
import { SelectOptions } from 'components/commonType';
import { MonthPicker } from 'components/MonthPicker/MonthPicker';

const defaultReportManager = new DefaultReportManager();

export const TenmaxReconciliation: React.FC = () => {

  const [reportType, setReportType] = useState<SelectOptions>();
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');
  const { loading, callAPIs } = useCallAPI();

  const handleOnChangeReportType = useCallback((value: SelectOptions) => {
    setReportType(value);
    if (value.value === ReconciliationReportType.MONTH) {
      setFrom(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      setTo(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
    } else {
      setFrom(moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'));
      setTo(moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'));
    }
  }, []);

  const downloadReport = useCallback(() => {
    if (reportType) {
      callAPIs([() => defaultReportManager.downloadTenmaxReconciliationReport(reportType.value as ReconciliationReportType, from, to)]);
    }
  }, [callAPIs, reportType, from, to]);

  const reportTypeOptions = useMemo(() => createSelectOptionsFromEnum(ReconciliationReportType, 'report.labels.tenmaxReconciliation.'), []);

  const reportTypeDatePickerElementMap: { [key: string | number]: JSX.Element } = useMemo(() => {
    const renderStandardDayRange = () => {
      const setUpStandardDayRange = (from: string | undefined, to: string | undefined) => {
        if (from) {
          setFrom(moment(from).startOf('day').format('YYYY-MM-DD'));
        }
        if (to) {
          setTo(moment(to).endOf('day').format('YYYY-MM-DD'));
        }
      };
      return (
        <DateRangePickerField
          label={i18n.t<string>('report.labels.reportGran')}
          startDate={from}
          endDate={to}
          maxDate={moment().endOf('day').subtract(1, 'day').format('YYYY-MM-DD')}
          format={'YYYY-MM-DD'}
          onChange={setUpStandardDayRange}
          showTimePicker={false}
        />
      );
    };
    const renderMonthRange = () => {
      const setUpMonthRange = (from: string | undefined) => {
        if (from) {
          setFrom(moment(from).startOf('month').format('YYYY-MM-DD'));
          setTo(moment(from).endOf('month').format('YYYY-MM-DD'));
        }
      };
      return (
        <CustomField formGroupClassName={styles.reportSearchBtnContainer} label={i18n.t<string>('report.labels.reportGran')} name='monthPicker'>
          <MonthPicker
            date={from}
            onChange={setUpMonthRange}
          />
        </CustomField>
      );
    };
    return {
      [ReconciliationReportType.STANDARD]: renderStandardDayRange(),
      [ReconciliationReportType.MONTH]: renderMonthRange()
    };
  }, [from, to]);

  return (
    <div className={styles.container}>
      {loading && <LoadingIndicator/>}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('report.titles.tenmaxReconciliation')}
        </div>
      </div>
      <div className={styles.contentArea}>
        <SelectField
          label={i18n.t<string>('report.labels.reportType')}
          name='reportType'
          options={reportTypeOptions}
          value={reportType}
          onChange={handleOnChangeReportType}
        />
        {reportType && reportTypeDatePickerElementMap[reportType.value]}
        {reportType &&
          <CustomField formGroupClassName={styles.reportSearchBtnContainer} label='' name='reportDownloadButton'>
            <Button variant='primary' onClick={downloadReport} size='sm'>
              {i18n.t<string>('report.labels.download')}
            </Button>
          </CustomField>
        }
      </div>
    </div>
  );
};
